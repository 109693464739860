<template>
  <section class="document-master-wrapper">
    <slot name="menu"></slot>

    <div class="content">
      <slot name="header"></slot>

      <component
        ref="step"
        class="step"
        :is="step.component"
        :step="step"
        :model-value="modelValue"
        @close="$emit('close')"
        @show-loader="$emit('show-loader')"
        @hide-loader="$emit('hide-loader')"
        @toggle-paging="$emit('toggle-paging', $event)"
        @contractors-change="$emit('contractors-change', $event)"
        @update:model-value="$emit('update:model-value', $event)"
      ></component>

      <slot name="footer"></slot>
    </div>
  </section>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'DocumentMasterWrapper',
  model: {
    prop: 'modelValue',
    event: 'update:model-value',
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    step: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:model-value', 'close', 'show-loader', 'hide-loader', 'toggle-paging', 'contractors-change'],
  methods: {
    validate() {
      return this.$refs.step.validate();
    },
    ready() {
      return this.$refs.step.ready();
    },
  },
});
</script>

<style scoped lang="scss">
.document-master-wrapper {
  display: flex;
}

.content {
  flex: 1 1 100%;
  min-height: 470px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.step {
  flex: 1 1 100%;
  margin-bottom: 20px;
}
</style>
