export const PRELOAD_IMAGES_COUNT = 2;
export const LOAD_IMAGE_ATTEMPTS_COUNT = 15;
export const RELOAD_IMAGE_DELAY = 2000;

export const BUTTON_TITLE_MIN_WIDTH = 432;

export const PREVIEW_FILES_EXTENSIONS = [
  'jpg',
  'jpeg',
  'doc',
  'docx',
  'docm',
  'pdf',
  'rtf',
  'odt',
  'ods',
];
