<template>
  <portal to="modal">
    <transition name="fade">
      <div
        v-if="isOpen"
        class="backdrop"
        :style="{zIndex}"
        @mousedown.self="isClosing = true"
        @click.self="isClosing = false"
        @mouseup.self="close"
      >
        <div class="content" :class="[`_size-${sizeComputed}`, {'_full-height': fullHeight}]">
          <slot>
            <div class="sheet" :class="{'_centered': popup}">
              <header v-if="$slots.header || title" class="header">
                <slot name="header">
                  <h2 class="title">{{ title }}</h2>
                </slot>
              </header>

              <div v-if="$slots.body" class="body" :class="{'_vertical-padding': popup}">
                <error-ui v-if="errorMessage" :text="errorMessage"></error-ui>
                <slot name="body"></slot>
                <loader-ui v-if="isLoading" :hidden-spinner="hiddenSpinner" :text="loaderText"></loader-ui>
              </div>

              <footer v-if="$slots.footer" class="footer">
                <slot name="footer"></slot>
              </footer>
            </div>
          </slot>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
import {defineComponent} from 'vue';
import LoaderUi from '@/components/ui/LoaderUi.vue';
import ErrorUi from '@/components/ui/ErrorUi.vue';
import {only} from '@/common/utils/props-validators';

export default defineComponent({
  name: 'ModalUi',
  components: {ErrorUi, LoaderUi},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    size: {
      type: String,
      // default: см. sizeComputed
      validator: only('xs', 's', 'm', 'l', 'xl', 'max'),
    },
    zIndex: {
      type: Number,
    },
    popup: {
      type: Boolean,
      default: false,
    },
    preventClosure: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
    },
    hiddenSpinner: {
      type: Boolean,
      default: false,
    },
    loaderText: {
      type: String,
      default: null,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'close', 'shown', 'hidden'],
  data() {
    return {
      isOpen: false,
      isLoading: false,
      isClosing: false,
    };
  },
  watch: {
    value(value) {
      this.isOpen = value;
      this.$emit(value ? 'shown' : 'hidden');
    },
    isOpen(isOpen) {
      if (isOpen) {
        document.addEventListener('keydown', this.onKeydown);
      } else {
        document.removeEventListener('keydown', this.onKeydown);
      }
    },
  },
  computed: {
    sizeComputed() {
      return this.size || (this.popup ? 'xs' : 's');
    },
  },
  methods: {
    show() {
      this.isOpen = true;
      this.$emit('input', true);
      this.$emit('shown');
    },
    hide() {
      this.isOpen = false;
      this.isLoading = false;
      this.$emit('input', false);
      this.$emit('hidden');
    },
    showLoader() {
      this.isLoading = true;
    },
    hideLoader() {
      this.isLoading = false;
    },
    close() {
      if (!this.isClosing) {
        return;
      }

      if (!this.preventClosure && !this.isLoading) {
        this.hide();
      }

      this.$emit('close');
    },
    onKeydown(event) {
      if (event.key === 'Escape') {
        this.close();
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/resolutions";

.backdrop {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  padding: 24px;

  overflow-y: auto;

  background: var(--color-backdrop);
  backdrop-filter: blur(5px);

  @media #{$tiny-and-less} {
    padding: 8px;
  }
}

.content {
  margin: 0 auto;

  &._full-height {
    height: 100%;

    .sheet,
    .body {
      height: 100%;
    }
  }

  &._size-xs {
    max-width: 300px;
  }

  &._size-s {
    max-width: 500px;
  }

  &._size-m {
    max-width: 700px;
  }

  &._size-l {
    max-width: 900px;
  }

  &._size-xl {
    max-width: 1140px;
  }
}

.sheet {
  position: relative;

  border-radius: 8px;
  background-color: white;

  &._centered {
    .title,
    .body {
      text-align: center;
    }

    .footer {
      justify-content: center;
    }
  }
}

.header {
  padding: 16px;
}

.body {
  padding: 0 16px;

  color: var(--color-gray-600);

  &._vertical-padding {
    padding: 16px;
  }

  &:first-child {
    padding-top: 16px;
  }

  &:last-child {
    padding-bottom: 16px;
  }
}

.footer {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  > :not(:last-child) {
    margin-right: 8px;
  }
}

.loader-ui {
  border-radius: 8px;
}

.fade {
  &-enter-active,
  &-leave-active {
    transition: opacity var(--transition);

    .content {
      transition: transform var(--transition);
    }
  }

  &-enter,
  &-leave-to {
    opacity: 0;

    .content {
      transform: translateY(-32px);
    }
  }
}
</style>
