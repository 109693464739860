<template>
  <nav class="document-master-steps">
    <button
      v-for="(step, index) in menu"
      :key="step"
      class="item"
      :class="{_active: isActive(index)}"
      :disabled="isDisabled(index)"
      @click="$emit('change', index)"
    >
      {{ step }}
    </button>
  </nav>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'DocumentMasterSteps',
  props: {
    page: {
      type: Number,
      required: true,
    },
    lastVisitedPage: {
      type: Number,
      required: true,
    },
    menu: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  methods: {
    isActive(index) {
      return index === this.page;
    },
    isDisabled(index) {
      return this.disabled || this.isActive(index) || index > this.lastVisitedPage;
    },
  },
});
</script>

<style scoped lang="scss">
.document-master-steps {
  padding: 8px;

  display: flex;
  flex-direction: column;

  background-color: var(--color-gray-075);
  border-radius: 8px;
}

.item {
  padding: 8px 12px;

  text-align: left;
  border-radius: 8px;

  &._active {
    color: var(--color-gray-700);
    background-color: var(--color-gray-100);
    border-left: var(--border-2-gray-700);
  }

  &:disabled {
    &:not(._active) {
      color: var(--color-gray-400);
    }
  }
}
</style>
