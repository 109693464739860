import StepLaunch from '@/components/document-master/steps/StepLaunch.vue';
import StepCode from '@/common/consts/document-master/stepCode';

export default {
  code: StepCode.Launch,
  title: 'Параметры запуска документа',
  menuTitle: 'Параметры запуска',
  onlyNext: true,
  component: StepLaunch,
};
