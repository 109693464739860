import StepContent from '@/components/document-master/steps/StepContent.vue';
import StepCode from '@/common/consts/document-master/stepCode';

export default {
  code: StepCode.Content,
  title: 'Создание документа',
  menuTitle: 'Содержание документа',
  noWrapper: true,
  size: 'xl',
  component: StepContent,
};
